<template>
    <div>
        <div class="title color-333" :style="titleStyle">
            <h4>{{ $t('subscribe.title1') }}</h4>
            <p>{{ $t('subscribe.title2') }}</p>
        </div>
        <el-card class="content-box d-flex-center bg-white my-20">
            <el-button class="mx-10" type="primary" plain @click="fatchData()">{{ $t('subscribe.new_currency') }}</el-button>
            <el-button class="mx-10" type="primary" plain @click="$nav.push('/subscribe/log')">{{ $t('subscribe.my_participation') }}</el-button>
            <el-button class="mx-10" type="primary" plain @click="$nav.push('/notice/details/28')">{{ $t('fund.incentive_mechanism') }}</el-button>
        </el-card>
        <div class="content-box pb-50 color-black">
            <el-card v-for="item in coinList.data" :key="item.id" class="mb-15">
                <div class="d-flex-center bg-white">
                    <el-avatar class="mr-20" shape="square" :size="68" :src="item.logo" />
                    <h3 class="text-center my-10 mr-20">{{ item.coin }}</h3>
                    <div class="flex-1 mr-20">
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="item.progress" />
                    </div>
                    <div class="mr-20 color-9 font-size-14">
                        End Date : {{ item.end_date }}
                    </div>
                    <el-button type="warning" icon="el-icon-info" plain class="ml-auto" native-type="button" @click="$nav.push('details/'+item.coin)">Details</el-button>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import iocBanner from '@/assets/ioc/banner.jpg'
export default {
    data() {
        return {
            titleStyle: {
                backgroundImage: 'url(\''+iocBanner+'\')'
            },
            coinList: {
                data: []
            },
            loading: false
        }
    },
    created: function () {
        this.fatchData()
    },
    methods: {
        fatchData: async function (){
            this.loading = true
            const { data } = await this.$request.post('coin')
            this.coinList = data
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.title{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    height: 280px;
    h4,
    p{
        margin: 0;
        text-align: center;
    }
    h4{
        font-size: 36px;
        padding-top: 70px;
    }
    p{
        font-size: 16px;
        padding-top: 40px;
    }
}
.data-list{
    color: #333;
    .data-item:hover{
        box-shadow: #409EFF 0 0 5px 1px;
    }
}
</style>